export const AppEditShape = () => import('../../components/app-edit-shape.vue' /* webpackChunkName: "components/app-edit-shape" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app-footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app-header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/app-image.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppSwiper = () => import('../../components/app-swiper.vue' /* webpackChunkName: "components/app-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppTitle = () => import('../../components/app-title.vue' /* webpackChunkName: "components/app-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
