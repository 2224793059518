// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../font/iconfont.woff2?t=1712629607730");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../font/iconfont.woff?t=1712629607730");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../font/iconfont.ttf?t=1712629607730");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"iconfont\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.iconfont{font-family:\"iconfont\"!important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-you:before{content:\"\\e64b\"}.icon-fanhui:before{content:\"\\e601\"}.icon-shouyetianchong:before{content:\"\\e692\"}.icon-weixin:before{content:\"\\e600\"}.icon-QQ:before{content:\"\\e612\"}.icon-24gf-phone24h:before{content:\"\\e966\"}body{background:#f6f6f6;color:#333}.clearfix:after{clear:both;content:\"\";display:block;width:100%}.text-theme{color:#007ac7}.bg-theme{background-color:#007ac7}.bg-fff{background-color:#fff}.hover\\:bg-theme:hover{background-color:#007ac7}.hover\\:tw-text-white:hover{color:#fff}.tw-bg-white{background:#fff}.tw-text-white{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
