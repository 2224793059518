
  export default{
    data(){
      return{
        list:[{
          name:"首页",
          "url":"./",
          key:"index",
        },{
          name:"产品中心",
          "url":"./product.html",
           key:"product",
        },{
          name:"行业动态",
          "url":"./news.html",
          key:"news",
        },{
          name:"关于汉卡",
          "url":"./about.html",
          key:"about",
        },{
          name:"常见问题",
          "url":"./answer.html",
          key:"answer",
        }]
      }
    },
    mounted(){
       let pageItem=this.list.find((f)=>{
          if(this.$route.name && this.$route.name.toLowerCase().indexOf(f.key)>-1){
             return true;
          }
       });
       if(pageItem){
         this.$set(pageItem,"selected",true);
       }
    },
    methods:{
      onJump(item){
         this.renderSelect(item);
      },
      renderSelect(item){
        for(let menu of this.list){
          this.$set(menu,"selected",false);
        }
        this.$set(item,"selected",true);
      }
    }
  }
