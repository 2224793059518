
   if(process.client){
     if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        //说明是移动端
        window.location.href="https://new.hankahuanbao.com/m.html";
     }
   }

   export default {
     components:{
     },
     data(){
       return {
       }
     },
     beforeCreate(){
       
     },
     head() {
       
     }
   }
 