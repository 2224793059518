import apiConfig from '@/config/fetch.js';

export default $axios => {
  const apis=apiConfig($axios);

  const homeMgr=apis.home;
  const productMgr=apis.product;
  const newsMgr=apis.news;
  return {
    homeMgr:{
    },
    productMgr:{
      getCateType:function(type){
        return productMgr.getCateType({type}).then((res)=>{
           return res.result;
        }).catch(err=>{
           console.log(err)
        });
      }
    },
    newsMgr:{
        getList:function(){
           return newsMgr.getList().then((res)=>{
              return res.result;
           }).catch(err=>{
              console.log(err)
           });
        },
        add:function(params){
          return newsMgr.add(params).then((res)=>{
             return res.result;
          });
        }
    }
  };
};
