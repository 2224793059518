



export default ({ app, $axios, store, route, redirect,req,res}) => {
  //第一次进入页面或者刷新页面是在server端运行，之后的路由跳转是在client端运行
  if(!route.name){
     redirect('/');
  }

  //console.log("process",process.server,process.client,process.static)
}
