
let apiBuilder = {
  get: (url) => {
    return {
      method: 'get',
      type: "get",
      url
    };
  },
  post: (url) => {
    return {
      method: 'post',
      type: "post",
      url
    };
  },
}

export default apiBuilder;
