import server from "@/utils/server/index.js";
let {
  get,
  post,
} = server;

let app = {
  home: {
  },
  product: {
    getCateType: get("/api/cate"),
  },
  news: {
    getList: get("/api/news/list"),
    add: post("/news/add"),
  },
};



export default $axios => {
     let list={};
     for(let key in app){
        list[key]={};
        for(let subKey in app[key]){
            list[key][subKey] = (params={}) => {
                let apiObj=app[key][subKey];

                //url处理
                let path = apiObj.url;
                if (apiObj.url.indexOf('[') > 0) {
                  for (let k in params) {
                    path = path.replace(`[${k}]`, params[k]);
                  }
                }

                //请求
                return $axios({
                  ...apiObj,
                  url:path,
                  ...(apiObj.method=="get" && {params:params}),
                  ...(apiObj.method=="post" && {data:params}),
                });
            }
        }
     }
     return list;
};
